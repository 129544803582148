.white-content {
	background: $light-bg;

	.navbar.navbar-transparent .navbar-brand {
		color: $black-states;
	}

	.navbar.navbar-transparent .navbar-toggler-bar {
		background: $black-states;
	}
	.navbar.navbar-transparent .navbar-nav li a:not(.dropdown-item) {
		color: $black-states;
		& i {
			color: $black-states;
		}
	}

	.navbar.navbar-transparent .navbar-minimize button i {
		color: $black-states;
	}

	.navbar.navbar-transparent .search-bar.input-group i {
		color: $black-states;
	}
	.navbar.navbar-transparent .search-bar.input-group .form-control {
		color: $default;
		&::placeholder {
			color: $dark-gray;
		}
	}

	.sidebar {
		box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.1), 0 4px 20px 0 rgba(0, 0, 0, 0.15);
		p {
			color: $opacity-8;
		}
	}

	.main-panel {
		background: $light-bg;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	ol li,
	ul li,
	pre {
		color: $black-states;
	}

	.font-icon-detail i {
		color: $black;
	}

	.btn:not([data-action]):hover {
		box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
	}

	.btn-neutral.btn-link {
		color: rgba($black, 0.7);
	}

	//style for inputs

	@include form-control-placeholder(rgba($black, 0.4), 1);
	.has-danger {
		.form-control,
		.input-group-prepend .input-group-text {
			border-color: $danger-states;
		}
	}

	.input-group-prepend .input-group-text {
		border-color: rgba($black-states, 0.5);
		color: $black-states;
	}

	.form-control {
		color: $black;
		border-color: rgba($black-states, 0.5);
		&:focus {
			border-color: $primary;
		}
	}

	.form-group.no-border,
	.input-group.no-border {
		.form-control,
		.form-control + .input-group-prepend .input-group-text,
		.form-control + .input-group-append .input-group-text,
		.input-group-prepend .input-group-text,
		.input-group-append .input-group-text {
			background-color: $opacity-gray-3;
			&:focus,
			&:active,
			&:active {
				background-color: $opacity-gray-5;
			}
		}

		.form-control {
			&:focus {
				& + .input-group-prepend .input-group-text,
				& + .input-group-append .input-group-text {
					background-color: $transparent-bg;
				}
			}
		}
	}

	.input-group[disabled] {
		.input-group-prepend .input-group-text,
		.input-group-append .input-group-text {
			background-color: $black;
		}
	}

	.form-control[disabled],
	.form-control[readonly],
	fieldset[disabled] .form-control {
		background: $light-gray;
		border-color: rgba($black-states, 0.3);
	}

	.input-group-focus {
		.input-group-prepend .input-group-text,
		.input-group-append .input-group-text,
		.form-control {
			border-color: $primary;
		}

		&.no-border {
			.input-group-prepend .input-group-text,
			.input-group-append .input-group-text {
				background-color: $opacity-gray-5;
			}
		}
	}
	.input-group-prepend .input-group-text {
		border-right: none;
	}

	.input-group-append .input-group-text {
		border-left: none;
	}

	.has-danger .form-control:focus,
	.has-success.input-group-focus .input-group-append .input-group-text,
	.has-success.input-group-focus .input-group-prepend .input-group-text {
		border-color: $danger-states;
	}

	.has-success .form-control:focus,
	.has-success.input-group-focus .input-group-append .input-group-text,
	.has-success.input-group-focus .input-group-prepend .input-group-text {
		border-color: darken($success, 10%);
	}

	.btn.btn-link {
		&:hover,
		&:active,
		&:focus {
			color: $dark-gray !important;
		}
	}

	.btn-group .btn.active {
		box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
	}

	.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-label {
		background: darken($light-bg, 10%);
	}

	.bootstrap-switch-off:before {
		background: rgba(darken($light-bg, 10%), 0.5);
	}

	.card:not(.card-white) {
		&.card-pricing,
		&.card-login,
		&.card-register {
			background: $card-black-background;
		}
		&.card-login,
		&.card-register {
			@include form-control-placeholder(#6c757c, 1);

			.form-control {
				border-color: lighten($black, 5%);
			}

			.form-group.no-border,
			.input-group.no-border {
				.form-control,
				.form-control + .input-group-prepend .input-group-text,
				.form-control + .input-group-append .input-group-text {
					background-color: lighten($black, 1%);
					&:focus,
					&:active,
					&:active {
						background-color: lighten($black, 2%);
					}
				}

				.form-control {
					&:focus {
						& + .input-group-prepend .input-group-text,
						& + .input-group-append .input-group-text {
							background-color: lighten($black, 2%);
						}
					}
				}

				.input-group-prepend .input-group-text,
				.input-group-append .input-group-text {
					background-color: lighten($black, 1%);
				}
			}

			.input-group-append .input-group-text,
			.input-group-prepend .input-group-text {
				border: 1px solid lighten($black, 5%);
				border-right: none;
				color: $white;
			}

			.input-group-focus {
				.input-group-prepend .input-group-text,
				.input-group-append .input-group-text,
				.form-control {
					border-color: $primary;
				}
			}

			.form-control {
				color: $white;
			}

			label {
				color: $opacity-6;
			}
		}
	}

	.card:not(.card-white) {
		background: $white;
		box-shadow: 0 1px 15px 0 rgba(123, 123, 123, 0.05);
		.card-header {
			color: $black;
			a[data-toggle='collapse'] {
				color: $black;
			}
		}
		.card-header .card-title,
		.card-body .card-title {
			color: $black-states;
		}

		.card-body {
			.card-category,
			.card-description {
				color: $black-states;
			}
		}

		label:not(.btn) {
			color: $default;
		}

		.pagination .page-item:not(.active) .page-link {
			color: $black-states;
			&:hover {
				background: rgba($dark-gray, 0.1);
			}
		}

		&:not(.card-wizard).nav-pills .nav-item .nav-link {
			color: $nav-gray;
			background-color: hsla(0, 0%, 87%, 0.3);
			&:not(.active):hover {
				background: hsla(0, 0%, 87%, 0.5);
			}
			&.active {
				color: $white;
			}
		}

		.tab-content .tab-pane {
			color: $nav-gray;
		}

		.card {
			box-shadow: none;
		}

		.card-collapse {
			.card {
				.card-body {
					color: $dark-gray;
				}
			}
		}

		&.card-contributions {
			span {
				color: $dark-gray;
			}
			.bootstrap-switch-container {
				span {
					color: $white;
				}
			}
		}
		&.card-plain {
			background: $transparent-bg;
			box-shadow: none;
		}

		&.card-tasks {
			.card-body {
				i {
					color: rgba(34, 42, 66, 0.7);
					&:hover {
						color: $black;
					}
				}
			}
		}
	}

	.table {
		> tbody > tr > td {
			color: rgba($black, 0.7) !important;
		}

		> thead > tr > th,
		> tbody > tr > th,
		> tfoot > tr > th,
		> thead > tr > td,
		> tbody > tr > td,
		> tfoot > tr > td {
			border-color: rgba($black, 0.2);
			padding: 12px 7px;
			vertical-align: middle;
		}

		> thead > tr > th,
		button.btn-neutral.btn-link {
			color: rgba($black, 0.7);
		}
	}

	.footer ul li a {
		color: $black-states;
	}

	.footer .copyright {
		color: $black-states;
	}

	.progress-container,
	.progress-container.progress.sm {
		.progress {
			background: rgba($black, 0.1);
			box-shadow: 0 0 0 3px rgba($black, 0.1);
			.progress-value {
				color: $default;
			}
		}

		.progress-badge {
			color: $black-states;
		}
	}

	.bootstrap-select button.dropdown-toggle {
		border-color: rgba($black-states, 0.5);
		.filter-option {
			color: $black-states;
		}

		&:after {
			color: $black-states;
		}
	}

	//style for calendar

	.fc-basic-view .fc-day-number,
	.fc-basic-view .fc-week-number {
		padding: 2px;
		color: $default;
	}

	.fc-unthemed .fc-today {
		background: darken($light-bg, 10%);
	}

	.fc-unthemed .fc-content,
	.fc-unthemed .fc-divider,
	.fc-unthemed .fc-list-heading td,
	.fc-unthemed .fc-list-view,
	.fc-unthemed .fc-popover,
	.fc-unthemed .fc-row,
	.fc-unthemed tbody,
	.fc-unthemed td,
	.fc-unthemed th,
	.fc-unthemed thead {
		border-color: $calendar-gray;
	}

	.fc-prev-button,
	.fc-next-button {
		color: $default;
		&:hover {
			color: $default;
		}
	}

	.fc button.btn-primary:not([data-action]):hover,
	.fc button:not([data-action]):hover {
		box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
	}

	.full-page {
		background: $light-bg;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		p,
		ol li,
		ul li,
		pre {
			color: $black-states;
		}

		.description {
			color: $dark-gray;
		}

		.footer ul li a {
			color: $black-states;
		}

		.footer .copyright {
			color: $black-states;
		}
	}

	.nav-pills .nav-item .nav-link:not(.active) {
		background: darken($light-bg, 10%);
		color: $black-states;
		&:hover {
			background: darken($white, 20%);
		}
	}

	.card-pricing:not(.card-white) {
		ul li {
			color: $white;
		}
	}

	.card:not(.card-pricing) {
		&.card-wizard {
			.card-body {
				background: $white;
			}
			.card-footer {
				background-color: $white !important;
			}

			.nav-pills .nav-item .nav-link p {
				color: $black-states;
			}
		}

		&.card-stats {
			hr {
				border-color: rgba($black-states, 0.1);
			}
			.card-title {
				color: $black-states;
			}
			.card-category,
			.stats {
				color: $card-stats-gray;
			}
		}
	}

	.card-timeline .timeline > li.timeline-inverted > .timeline-panel {
		background: $white;
		.timeline-body p {
			color: $black-states;
		}

		&:after,
		&:before {
			border-right-color: $white;
			border-left-color: $white;
		}
	}

	.card-timeline .timeline:before {
		background-color: $light-gray;
	}
}
